@charset "UTF-8";

//
// サイト全体で共通のアニメーション
//

// フェード
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
